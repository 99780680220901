import { jwtDecode } from 'jwt-decode';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

//-----------------------|| AUTH GUARD ||-----------------------//

// Helper function to check if the token is expired
const isTokenExpired = (token) => {
    try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Current time in seconds
        return decoded.exp < currentTime;
    } catch (error) {
        return true; // If token is invalid, consider it expired
    }
};

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
    const account = useSelector((state) => state.account);
    const { isLoggedIn, token } = account;

    // Check if the user is logged in and if the token is valid
    if (!isLoggedIn || isTokenExpired(token)) {
        // Remove token from local storage
        localStorage.removeItem('sourcefloai-account');
        // Reload the page
        // window.location.reload();
        // Redirect to login
        return <Redirect to="/login" />;
    }

    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
