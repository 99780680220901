// LandingPage.js
import React from 'react';
import '../../../assets/styles/style.css';
import Features from './Features';
import Footer from './Footer';
import Hero from './Hero';
import Navbar from './Navbar';
import Resources from './Resources';
import Testimonials from './Testimonials';

const LandingPage = () => {
    // const account = useSelector((state) => state.account);
    // const { isLoggedIn } = account;
    // const dispatcher = useDispatch();

    // const handleLogout = () => {
    //     axios
    //         .post(configData.API_SERVER + 'users/logout', { token: `${account.token}` }, { headers: { Authorization: `${account.token}` } })
    //         .then(function (response) {
    //             dispatcher({ type: LOGOUT });
    //         })
    //         .catch(function (error) {
    //             console.log('error - ', error);
    //         });
    // };

    return (
        <div>
            <Navbar />
            {/* <header className="header">
                <div className="wrapper header__wrapper">
                    <img src={logo} alt="Sourceflo.ai" width="182" className="header__logo-link" />
                    <nav className="header__nav">
                        <Link to="/dashboard/default" className="header__nav-link link">
                            Dashboard
                        </Link>
                        {isLoggedIn ? (
                            <button onClick={handleLogout} className="header__nav-link link highlight">
                                Logout
                            </button>
                        ) : (
                            <Link to="/login" className="header__nav-link link highlight">
                                Login
                            </Link>
                        )}
                    </nav>
                </div>
            </header> */}
            <main>
                <Hero />
                <Resources />
                <Features />
                <Testimonials />
                {/* <PricingSection /> */}
            </main>
            <Footer />
        </div>
    );
};

export default LandingPage;
