import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';

export default function Testimonials() {
    return (
        <Box sx={{ py: 10, background: 'linear-gradient(to bottom right, #f0f8ff, white, #f0f8ff)' }}>
            <Container maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h1" sx={{ fontWeight: 'bold', mb: 3 }}>
                            WHY
                            <br />
                            SOURCEFLO.AI ?
                        </Typography>
                        <Typography variant="h3" sx={{ mb: 4 }}>
                            Simplify complex procurement processes with
                            <br />a platform designed to:
                        </Typography>
                        <Box component="ul" sx={{ pl: 2, color: 'text.secondary', listStyleType: 'disc', pl: 4 }}>
                            <li>Automate time-consuming RF(x) workflows.</li>
                            <li>Centralize communications for better collaboration.</li>
                            <li>Provide actionable insights powered by AI.</li>
                            <li>Trusted by small and medium-sized businesses across industries.</li>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                            <Box sx={{ backgroundColor: 'white', borderRadius: 2, p: 3, boxShadow: 3 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                                    <img
                                        src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&w=150&q=80"
                                        alt="Olivia Wilson"
                                        style={{ width: 48, height: 48, borderRadius: '50%', objectFit: 'cover' }}
                                    />
                                    <Box>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                            Olivia Wilson
                                        </Typography>
                                        <Box sx={{ display: 'flex', color: 'yellow.400' }}>{'★'.repeat(5)}</Box>
                                    </Box>
                                </Box>
                                <Typography color="textSecondary">
                                    "SourceFlo transformed how we manage suppliers – faster and more efficient!"
                                </Typography>
                            </Box>
                            <Box sx={{ backgroundColor: 'white', borderRadius: 2, p: 3, boxShadow: 3 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                                    <img
                                        src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?auto=format&fit=crop&w=150&q=80"
                                        alt="Chris Woakes"
                                        style={{ width: 48, height: 48, borderRadius: '50%', objectFit: 'cover' }}
                                    />
                                    <Box>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                            Chris Woakes
                                        </Typography>
                                        <Box sx={{ display: 'flex', color: 'yellow.400' }}>{'★'.repeat(5)}</Box>
                                    </Box>
                                </Box>
                                <Typography color="textSecondary">"The AI tools have saved us hours on RFQ prep and tracking."</Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
