import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import React from 'react';

export default function Features() {
    return (
        <Box sx={{ py: 10, background: 'linear-gradient(to bottom right, #f0f8ff, white, #f0f8ff)' }}>
            <Container maxWidth="lg">
                <Typography variant="h1" align="center" gutterBottom sx={{ fontWeight: 'bold', mb: 4 }}>
                    FEATURES
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} sx={{ p: 3, display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                            <Box
                                sx={{
                                    backgroundColor: '#c41e3a',
                                    color: 'white',
                                    fontSize: '1.5rem',
                                    fontWeight: 'bold',
                                    p: 2,
                                    borderRadius: '50%',
                                    minWidth: '4rem',
                                    height: '4rem',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                01
                            </Box>
                            <Box>
                                <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1 }}>
                                    AI-Powered RF(x) Management
                                </Typography>
                                <Typography color="textSecondary">
                                    Automate and optimize every step of your RFQ process for faster, smarter sourcing decisions.
                                </Typography>
                            </Box>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} sx={{ p: 3, display: 'flex', alignItems: 'flex-start', gap: 2, mt: { md: 6 } }}>
                            <Box
                                sx={{
                                    backgroundColor: '#c41e3a',
                                    color: 'white',
                                    fontSize: '1.5rem',
                                    fontWeight: 'bold',
                                    p: 2,
                                    borderRadius: '50%',
                                    minWidth: '4rem',
                                    height: '4rem',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                02
                            </Box>
                            <Box>
                                <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1 }}>
                                    Seamless Supplier Communication
                                </Typography>
                                <Typography color="textSecondary">
                                    Foster collaboration with built-in messaging and tracking tools.
                                </Typography>
                            </Box>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} sx={{ p: 3, display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                            <Box
                                sx={{
                                    backgroundColor: '#c41e3a',
                                    color: 'white',
                                    fontSize: '1.5rem',
                                    fontWeight: 'bold',
                                    p: 2,
                                    borderRadius: '50%',
                                    minWidth: '4rem',
                                    height: '4rem',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                03
                            </Box>
                            <Box>
                                <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1 }}>
                                    Value Tracking Over Time
                                </Typography>
                                <Typography color="textSecondary">Monitor RFQ impact and ROI through data-driven insights.</Typography>
                            </Box>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} sx={{ p: 3, display: 'flex', alignItems: 'flex-start', gap: 2, mt: { md: 6 } }}>
                            <Box
                                sx={{
                                    backgroundColor: '#c41e3a',
                                    color: 'white',
                                    fontSize: '1.5rem',
                                    fontWeight: 'bold',
                                    p: 2,
                                    borderRadius: '50%',
                                    minWidth: '4rem',
                                    height: '4rem',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                04
                            </Box>
                            <Box>
                                <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1 }}>
                                    Affordable Procurement Solutions
                                </Typography>
                                <Typography color="textSecondary">Transparent pricing that grows with your business.</Typography>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
