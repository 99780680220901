import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';

export default function Resources() {
    return (
        <Box
            sx={{
                py: 10,
                background: 'linear-gradient(to bottom right, #f0f8ff, white, #f0f8ff)'
            }}
        >
            <Box sx={{ maxWidth: 'lg', mx: 'auto', px: 2 }}>
                <Typography variant="h1" component="h2" fontWeight="bold" textAlign="center" mb={4}>
                    CHOOSE YOUR
                    <br />
                    FREE RESOURCE
                </Typography>
                <Typography variant="h3" textAlign="center" mb={6} color="textSecondary" maxWidth="md" mx="auto">
                    Kickstart your procurement journey with expert tools designed to give you a competitive edge.
                </Typography>
                <Grid container spacing={4} justifyContent="center" maxWidth="md" mx="auto">
                    <Grid item xs={12} md={6}>
                        <Box
                            sx={{
                                backgroundColor: '#c41e3a',
                                color: 'white',
                                p: 4,
                                borderRadius: 4,
                                boxShadow: 3,
                                transition: 'transform 0.3s',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                height: '100%',
                                '&:hover': {
                                    transform: 'scale(1.05)'
                                }
                            }}
                        >
                            <Box>
                                <Typography
                                    variant="h5"
                                    component="h3"
                                    color={'#FFD700'}
                                    fontWeight="bold"
                                    mb={2}
                                    textTransform="uppercase"
                                >
                                    Unleashing the AI
                                    <br />
                                    Cashflow Machine
                                </Typography>
                                <Typography mb={4} textTransform="uppercase" fontSize="small" letterSpacing={1} lineHeight={1.5}>
                                    A Comprehensive Guide to
                                    <br />
                                    Leveraging AI in
                                    <br />
                                    Procurement for Cost
                                    <br />
                                    Reduction and Process
                                    <br />
                                    Optimization
                                </Typography>
                            </Box>
                            <a
                                href="https://docs.google.com/forms/d/e/1FAIpQLSc0NsGGGWFSybaQDnOTAQrRYearcsLAq1QXtTO8douBMEPVgw/viewform?usp=sf_link"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'none' }}
                            >
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: 'white',
                                        color: '#c41e3a',
                                        py: 1.5,
                                        borderRadius: '50px',
                                        fontWeight: 'medium',
                                        '&:hover': {
                                            backgroundColor: '#f5f5f5'
                                        }
                                    }}
                                >
                                    Download the Book
                                </Button>
                            </a>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box
                            sx={{
                                backgroundColor: '#c41e3a',
                                color: 'white',
                                p: 4,
                                borderRadius: 4,
                                boxShadow: 3,
                                transition: 'transform 0.3s',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                height: '100%',
                                '&:hover': {
                                    transform: 'scale(1.05)'
                                }
                            }}
                        >
                            <Box>
                                <Typography
                                    variant="h5"
                                    component="h3"
                                    color={'#FFD700'}
                                    fontWeight="bold"
                                    mb={2}
                                    textTransform="uppercase"
                                >
                                    RFX Risk and
                                    <br />
                                    Readiness Checklist
                                </Typography>
                                <Typography mb={4} textTransform="uppercase" fontSize="small" letterSpacing={1} lineHeight={1.5}>
                                    Ensure your Procurement
                                    <br />
                                    Process is Risk-Free and
                                    <br />
                                    Supplier-Ready with this
                                    <br />
                                    Essential Checklist
                                </Typography>
                            </Box>
                            <a
                                href="https://docs.google.com/forms/d/e/1FAIpQLSc0NsGGGWFSybaQDnOTAQrRYearcsLAq1QXtTO8douBMEPVgw/viewform?usp=sf_link"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'none' }}
                            >
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: 'white',
                                        color: '#c41e3a',
                                        py: 1.5,
                                        borderRadius: '50px',
                                        fontWeight: 'medium',
                                        '&:hover': {
                                            backgroundColor: '#f5f5f5'
                                        }
                                    }}
                                >
                                    Get the Checklist
                                </Button>
                            </a>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
