import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Box, Container, Link, Typography } from '@mui/material';
import React from 'react';
import Logo from '../../../assets/images/Logo.png';

export default function Footer() {
    return (
        <Box sx={{ backgroundColor: 'black', color: 'white', py: 4 }}>
            <Container maxWidth="md">
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img src={Logo} alt="SOURCEFLO.AI" style={{ height: '32px', marginBottom: '24px' }} />
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 4, mb: 2 }}>
                        <Link href="/" color="inherit" underline="hover" sx={{ '&:hover': { color: 'gray.300' } }}>
                            Terms of Service
                        </Link>
                        <Link href="/" color="inherit" underline="hover" sx={{ '&:hover': { color: 'gray.300' } }}>
                            Privacy Policy
                        </Link>
                    </Box>
                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                        <a
                            href="https://www.facebook.com/profile.php?id=61566316237229"
                            style={{ color: '#fff', textDecoration: 'none', margin: '0 10px' }}
                        >
                            <FacebookIcon />
                        </a>
                        <a href="https://x.com/PMandCoAI" style={{ color: '#fff', textDecoration: 'none', margin: '0 10px' }}>
                            <TwitterIcon />
                        </a>

                        <a
                            href="https://www.linkedin.com/in/paul-malott/"
                            style={{ color: '#fff', textDecoration: 'none', margin: '0 10px' }}
                        >
                            <LinkedInIcon />
                        </a>
                    </div>
                    <Typography variant="body2" color="textSecondary">
                        2024 Sourceflo.ai - Empowering Global Procurement
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
}
