import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import Header from '../../../assets/images/Header-image.png';
import Robot from '../../../assets/images/Robot-hand.png';
import configData from '../../../config'; // Adjust the path as necessary

export default function Hero() {
    return (
        <Box sx={{ position: 'relative', backgroundColor: '#D6EDF2', py: 10 }}>
            <Box sx={{ maxWidth: 'lg', mx: 'auto', px: 2 }}>
                <Grid container spacing={4} alignItems="center" justifyContent="space-between">
                    {/* Left Section - Text Content */}
                    <Grid item xs={12} lg={6}>
                        <Typography className="information__heading" variant="h3" component="h1" fontWeight="bold" mb={2} lineHeight={1.2}>
                            PROCUREMENT <br />
                            <Box component="span" sx={{ color: '#b41917' }}>
                                REDEFINED
                            </Box>
                        </Typography>
                        <Typography variant="h3" mb={2} color="textPrimary">
                            Streamline RFQs, RFPs, and Supplier Collaboration with AI-Powered Efficiency
                        </Typography>
                        <Typography mb={4} color="textSecondary">
                            An intuitive platform to manage RF(x) processes, enhance supplier relationships, and maximize value over time –
                            at a price that works for you.
                        </Typography>

                        <Formik
                            initialValues={{
                                email: ''
                            }}
                            validationSchema={Yup.object({
                                email: Yup.string().email('Invalid email address').required('Required')
                            })}
                            onSubmit={(values, { setSubmitting, resetForm, setErrors }) => {
                                axios
                                    .post(`${configData.API_SERVER}waitlist`, { email: values.email })
                                    .then((response) => {
                                        alert('You have been added to the waitlist!');
                                        resetForm();
                                        setSubmitting(false);
                                    })
                                    .catch((error) => {
                                        setErrors({ submit: error.response.data.msg || 'Could not add to waitlist' });
                                        setSubmitting(false);
                                    });
                            }}
                        >
                            {(formik) => (
                                <form onSubmit={formik.handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                                    <TextField
                                        type="email"
                                        name="email"
                                        label="Enter your email for waitlist"
                                        variant="outlined"
                                        fullWidth
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                        sx={{ maxWidth: '400px' }} // Set a specific width for the input
                                    />
                                    <Button
                                        type="submit"
                                        variant="outlined"
                                        sx={{
                                            borderColor: '#b41917',
                                            color: '#b41917',
                                            px: 3,
                                            py: 1.5,
                                            borderRadius: '50px',
                                            fontWeight: 'medium',
                                            maxWidth: '200px', // Set a specific width for the button
                                            '&:hover': {
                                                backgroundColor: '#b41917',
                                                color: 'white'
                                            }
                                        }}
                                        disabled={formik.isSubmitting}
                                    >
                                        Join Waitlist
                                    </Button>
                                    {formik.errors.submit && <Typography color="error">{formik.errors.submit}</Typography>}
                                </form>
                            )}
                        </Formik>
                    </Grid>

                    {/* Right Section - Images */}
                    <Grid item xs={12} lg={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box sx={{ position: 'relative', width: 350, height: 350 }}>
                            <Box
                                component="img"
                                src={Header}
                                alt="Business Professional"
                                sx={{
                                    borderRadius: '50%',
                                    width: 250,
                                    height: 250,
                                    objectFit: 'cover',
                                    boxShadow: 3,
                                    position: 'relative',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    zIndex: 0
                                }}
                            />
                            <Box
                                component="img"
                                src={Robot}
                                alt="AI Technology"
                                sx={{
                                    position: 'absolute',
                                    bottom: -64,
                                    right: 0,
                                    width: 320,
                                    height: 'auto',
                                    objectFit: 'contain',
                                    zIndex: 10
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
