import { CircularProgress } from '@material-ui/core';
import { AppBar, Box, Button, Toolbar } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Logo from '../../../assets/images/Logo.png';
import configData from '../../../config'; // Adjust the path as necessary
import { LOGOUT } from '../../../store/actions'; // Adjust the path as necessary

export default function Navbar() {
    const account = useSelector((state) => state.account);
    const [loggingOut, setLoggingOut] = useState(false); // Add state to track logout process

    const { isLoggedIn } = account;
    const dispatch = useDispatch();

    const handleLogout = () => {
        setLoggingOut(true); // Disable button and show loader during logout

        axios
            .post(`${configData.API_SERVER}users/logout`, { token: `${account.token}` }, { headers: { Authorization: `${account.token}` } })
            .then((response) => {
                if (response.status === 200 && response.data.success) {
                    dispatch({ type: LOGOUT });
                    // window.location.reload(); // Reload the page after successful logout
                } else {
                    console.log('Logout failed:', response.data);
                }
            })
            .catch((error) => {
                console.log('Error during logout:', error);
            })
            .finally(() => setLoggingOut(false)); // Re-enable button after the logout attempt
    };

    return (
        <AppBar position="sticky" sx={{ backgroundColor: 'black', py: 1 }}>
            <Toolbar sx={{ maxWidth: 'lg', mx: 'auto', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <img src={Logo} alt="SOURCEFLO.AI" style={{ height: '32px', width: 'auto' }} />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                    <Button
                        component={Link}
                        to="/dashboard/default"
                        sx={{ color: 'white', fontWeight: 'medium', '&:hover': { color: 'gray' } }}
                    >
                        Dashboard
                    </Button>
                    {isLoggedIn ? (
                        <Button
                            onClick={handleLogout}
                            disabled={loggingOut}
                            sx={{
                                backgroundColor: '#b41917',
                                color: 'white',
                                px: 4,
                                py: 1,
                                borderRadius: '50px',
                                fontWeight: 'medium',
                                '&:hover': {
                                    backgroundColor: '#b41917',
                                    opacity: 0.9
                                }
                            }}
                        >
                            {loggingOut ? <CircularProgress color="inherit" size={24} /> : 'Logout'}
                        </Button>
                    ) : (
                        <Button
                            component={Link}
                            to="/login"
                            sx={{
                                backgroundColor: '#b41917',
                                color: 'white',
                                px: 4,
                                py: 1,
                                borderRadius: '50px',
                                fontWeight: 'medium',
                                '&:hover': {
                                    backgroundColor: '#b41917',
                                    opacity: 0.9
                                }
                            }}
                        >
                            Login
                        </Button>
                    )}
                </Box>
            </Toolbar>
        </AppBar>
    );
}
